











import { Component, Mixins, Watch } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory, MethodOfSale, PropertyCategory } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})
export default class PropertiesForSale extends Mixins(View) {
  private listingFilter: ListingFilter | null = null;

  mounted() {
    this.updateFilter();
  }

  @Watch('$route.query')
  updateFilter() {
    let propertyCategories: PropertyCategory[] = [];

    if (this.$route.query.ListingCategory) {
      // eslint-disable-next-line radix
      propertyCategories = (this.$route.query.ListingCategory! as string).split(',').map((v) => parseInt(v) as PropertyCategory);
    }

    const filter = new ListingFilter({
      PropertyCategories: propertyCategories,
      Statuses: [
        ListingStatus.Current,
        ListingStatus.UnderContract,
      ],
      Categories: [
        ListingCategory.Commercial,
        ListingCategory.CommercialLand,
      ],
      MethodsOfSale: [
        MethodOfSale.Sale,
        MethodOfSale.Both,
        MethodOfSale.Auction,
        MethodOfSale.Tender,
        MethodOfSale.EOI,
        MethodOfSale.ForSale,
        MethodOfSale.Offers,
      ],
      PageSize: 9,
      OrderByStatements: [
        ListingOrderBy.CreateDateDesc,
      ],
    });

    if (this.$route.query.keywordOptions) {
      const keywordOpts = (this.$route.query.keywordOptions as string).split('|').map((opt) => {
        const split = opt.split(':');

        return {
          Type: split[0],
          Value: split[1],
        };
      });

      filter.Suburbs = keywordOpts.filter((opt) => opt.Type === 'suburb').map((opt) => opt.Value);

      const categories = keywordOpts.filter((opt) => opt.Type === 'categories').map((opt) => parseInt(opt.Value, 10));

      if (categories.length > 0) {
        filter.PropertyCategories = categories;
      }

      const searchVal = keywordOpts.find((opt) => opt.Type === 'search');

      if (searchVal) {
        filter.SearchTerm = searchVal.Value;
      }
    }

    if (this.$route.query.areaMin) filter.MinFloorArea = this.$route.query.areaMin as string;
    if (this.$route.query.areaMax) filter.MaxFloorArea = this.$route.query.areaMax as string;

    if (this.$route.query.priceMin) filter.MinPrice = this.$route.query.priceMin as string;
    if (this.$route.query.priceMax) filter.MaxPrice = this.$route.query.priceMax as string;
    if (this.$route.query.suburbs) filter.Suburbs = (this.$route.query.suburbs as string).split('|');

    if (this.$route.query.isVacantPosession) filter.IsVacant = true;
    if (this.$route.query.isTenanted) filter.IsTenanted = true;
    if (this.$route.query.isStandalone) filter.IsWholeBuilding = true;
    if (this.$route.query.isStrata) filter.IsVacant = true;

    this.listingFilter = filter;
  }

  handleFilter(filter: ListingFilter) {
    this.listingFilter = filter;
  }
}
